.react-flow__node-output {

}

.side-title {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    height: 50px;
    cursor: default !important;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    background: #a4e2f1;
    z-index: 999;
}

.side-options {
    position: absolute;
    top: 0;
    width: calc(100% - 200px);
    height: 50px;
    left: 200px;
    background: #1daccf;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    box-sizing: border-box;
    transition: 0.2s ease all;
    align-items: center;
    z-index: 999;
}

.side-options.active {
    left: 30px;
    width: calc(100% - 30px);
}

.side-options a#side-nav-dropdown-save, .side-options > div {
    color: #fff;
    align-items: center;
    display: flex;
    cursor: pointer;
    gap: 5px;
}

.side-options a#side-nav-dropdown-save:hover, .side-options > div:hover {
    color: #e3e3e3;
    text-decoration: underline;
}

.side-options .dropdown-menu.show.dropdown-menu-dark {
    background: #5aceea;
}

.side-options .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd;
}

.side-save {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.side-save > div {
    height: 100%;
    background: #1daccf;
    align-items: center;
    justify-content: center;
    color: white;
    display: flex;
    padding: 0 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 19px;
}

.side-save > div:hover {
    color: #000;
}

.side-save > div:first-of-type {
    background: #FFC108;
}

.side-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;
    align-items: center;
    justify-content: flex-end;
    flex: auto;
}

.side-wrapper:hover {
    transform: scale(1.1);
}

.side-wrapper:nth-child(odd) {
    padding-right: 2px;
}

.side-wrapper:nth-child(even) {
    padding-left: 2px;
}

.side-wrapper label {
    font-size: 13px;
    font-weight: bold;
    color: #000;
    height: 20px;
    line-height: 14px;
}

.side-panel {
    background: url('../../images/panel.png') !important;
    background-size: 195% !important;
    border: none !important;
    height: 33px;
    width: 13px !important;
    color: white;
    font-size: 23px;
    font-weight: bold;
    text-shadow: 0 1px black;
}

.side-inverter {
    background: url('../../images/inverter.png') !important;
    background-size: contain !important;
    border: none !important;
    height: 33px;
    width: 46px !important;
    color: white;
    font-size: 23px;
    font-weight: bold;
    text-shadow: 0 1px black;
}

.side-mppt {
    background: url('../../images/mppt.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 34px;
    width: 16px !important;
    color: white;
    font-size: 23px;
    font-weight: bold;
    text-shadow: 0 1px black;
}

.side-pwm {
    background: url('../../images/pwm.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 34px;
    width: 16px !important;
    color: white;
    font-size: 23px;
    font-weight: bold;
    text-shadow: 0 1px black;
}

.side-solargenerator {
    background: url('../../images/solargenerator.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 47px;
    width: 60px !important;
    color: white;
    font-size: 23px;
    font-weight: bold;
    text-shadow: 0 1px black;
}

.side-battery {
    background: url('../../images/battery.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 39px;
    width: 53px !important;
}

.side-battery-fuse {
    background: url('../../images/batteryfuse.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 29px;
    margin-top: 10px;
    width: 63px !important;
    color: white;
    font-size: 21px;
    font-weight: bold;
    text-shadow: 0 1px black;
}

.side-cable-entry-gland {
    background: url('../../images/cableentrygland.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 40px;
    width: 27px !important;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0 1px black;
    padding-left: 1px;
}

.side-circuit-breaker {
    background: url('../../images/circuitbreaker.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 40px;
    width: 27px !important;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0 1px black;
    padding-left: 1px;
}

.side-electric-breaker {
    background: url('../../images/electricbreaker.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 40px;
    width: 17px !important;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0 1px black;
    padding-left: 1px;
}

.side-bus-bar {
    background: url('../../images/busbar.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 23px;
    width: 81px !important;
    margin-top: 10px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0 1px black;
    padding-left: 1px;
}

.side-ground {
    background: url('../../images/ground.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 36px;
    width: 36px !important;
    margin-top: 0;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0 1px black;
    padding-left: 1px;
}

.side-dcdc {
    background: url('../../images/dcdc.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 44px;
    width: 48px !important;
    margin-top: 10px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0 1px black;
    padding-left: 1px;
}

.side-usbload {
    background: url('../../images/usbload.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 47px;
    width: 37px !important;
    margin-top: 10px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0 1px black;
    padding-left: 1px;
}

.side-switch {
    background: url('../../images/switch.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 36px;
    width: 36px !important;
    margin-top: 19px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0 1px black;
    padding-left: 1px;
}

.side-switch4 {
    background: url('../../images/switch4.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 36px;
    width: 36px !important;
    margin-top: 19px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0 1px black;
    padding-left: 1px;
}

.side-textbox {
    background: #eaeaea !important;
    border: none !important;
    height: 33px;
    width: 73px !important;
    margin-top: 0;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0 1px black;
    padding-left: 1px;
    padding-top: 0;
}

.side-textbox span {
    text-shadow: none;
    color: #000;
    font-size: 12px;
}

.side-text {
    border: 1px solid #eaeaea !important;
    height: 33px;
    width: 73px !important;
    margin-top: 0;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-shadow: 0 1px black;
    padding-left: 1px;
    padding-top: 0;
}

.side-text span {
    text-shadow: none;
    color: #000;
    font-size: 12px;
}

.side-voltage {
    background: url('../../images/voltage.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 39px;
    width: 53px !important;
}

.side-acload {
    background: url('../../images/acoutput.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 50px;
    width: 75px !important;
}

.side-dcload {
    background: url('../../images/dcoutput.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 50px;
    width: 75px !important;
    margin-top: 7px;
}

.side-inlinefuse {
    background: url('../../images/inlinefuse.png') !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: none !important;
    height: 39px;
    width: 53px !important;
}

.social-link {
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0,0,0,0.1);
    padding-top: 15px;
    justify-content: space-around;
    margin: 0 -15px;
}

.social-link-icon img {
    transform: scale(1);
    transform-origin: center;
    transition: 200ms ease all;
}

.social-link-icon:hover img {
    transform: scale(1.1);
}

.social-title {
    font-weight: bold;
    text-align: center;
    cursor: default !important;
}

.social-tooltip  span:nth-child(3) {
    font-size: 12px;
}

.tooltip.show {
    opacity: 0.99 !important;
}

.bs-tooltip-top > .tooltip-inner {
    background: #1daccf;
}

.bs-tooltip-top > .tooltip-arrow:before {
    border-top-color: #1daccf;
}
