.dcdc-node {
    background: url('../../images/dcdc.png');
    padding: 10px;
    background-size: cover;
    height: 90px;
    width: 97px;
    position: relative;
}

.dcdc-node .dcdc-node-input-label {
    width: 250px;
    position: absolute;
    top: -30px;
    left: -76px;
    text-align: center;
}
