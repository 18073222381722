.dcload-node {
    background-image: url('../../images/dcoutput.png');
    padding: 10px;
    background-size: cover;
    height: 63px;
    width: 95px;
    position: relative;
}

.dcload-node .dcload-node-input-label {
    width: 100px;
    height: 20px;
    position: absolute;
    top: 30px;
    left: -15px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #0092d3;
}

.dcload-node .dcload-node-input-label:hover {
    cursor: text;
}
