.react-flow__node-resize {
    z-index: 0 !important;
}
.react-flow__node.react-flow__node-text.selected.selectable {
    border: none;
}

.resize-text-node {
    padding: 10px;
    width: auto !important;
    height: auto !important;
    position: relative;
}

.resize-text-node.active {
    border: 1px solid orange;
}

.resize-text-node .node-remove {
    right: -20px !important;
}

.resize-text-node .resize-text-node-input-label {
    min-width: 100px;
    cursor: text;
    text-align: center;
}

.resize-text-node .resize-text-node-input-label:hover {
    cursor: text;
}

.resize-text-node .resize-text-node-input-label:focus-visible {
    border: 1px solid red;
    outline-style: none;
}
