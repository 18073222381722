.usbload-node {
    background-image: url('../../images/usbload.png');
    padding: 10px;
    background-size: cover;
    height: 65px;
    width: 51px;
    position: relative;
}

.usbload-node .usbload-node-input-label {
    width: 200px;
    position: absolute;
    top: 63px;
    left: 50%;
    transform: translateX(-50%);
}
