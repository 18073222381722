.battery-node {
    background-image: url('../../images/battery.png');
    padding: 10px;
    background-size: cover;
    height: 79px;
    width: 108px;
    position: relative;
}

.battery-node .battery-node-input-label {
    width: 200px;
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
}
