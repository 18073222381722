.solargenerator-node {
    background-image: url('../../images/solargenerator.png');
    padding: 10px;
    background-size: cover;
    height: 156px;
    width: 194px;
    position: relative;
}

.solargenerator-node .solargenerator-node-input-label {
    width: 300px;
    height: 20px;
    position: absolute;
    top: -26px;
    left: -43px;
    text-align: center;
}
