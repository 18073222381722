.circuitbreaker-node {
    background-image: url('../../images/circuitbreaker.png');
    padding: 10px;
    background-size: cover;
    height: 83px;
    width: 56px;
    position: relative;
}

.circuitbreaker-node .circuitbreaker-node-input-label {
    width: 200px;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
}

.circuitbreaker-node .node-remove {
    right: -36% !important;
}
