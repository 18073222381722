.pwm-node {
    background-image: url('../../images/pwm.png');
    padding: 10px;
    background-size: cover;
    height: 132px;
    width: 83px;
    position: relative;
}

.pwm-node .pwm-node-input-label {
    width: 300px;
    height: 20px;
    position: absolute;
    top: -23px;
    left: -107px;
    text-align: center;
}
