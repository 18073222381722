.panel-node {
    background-image: url('../../images/panel.png');
    padding: 10px;
    background-size: cover;
    height: 223px;
    width: 135px;
    position: relative;
}

.panel-node .panel-node-input-label {
    width: 180px;
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
}
