.acload-node {
    background-image: url('../../images/acoutput.png');
    padding: 10px;
    background-size: cover;
    height: 63px;
    width: 95px;
    position: relative;
}

.acload-node .acload-node-input-label {
    width: 100px;
    height: 20px;
    position: absolute;
    top: 30px;
    left: -15px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #d3d508;
}

.acload-node .acload-node-input-label:hover {
    cursor: text;
}
