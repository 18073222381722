.cableentrygland-node {
    background-image: url('../../images/cableentrygland.png');
    padding: 10px;
    background-size: cover;
    height: 83px;
    width: 56px;
    position: relative;
}

.cableentrygland-node .cableentrygland-node-input-label {
    width: 200px;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
}

.cableentrygland-node .node-remove {
    right: -36% !important;
}
