.dndflow {
    flex-direction: column;
    height: 100%;
    position: relative;
}

.dndflow-load, .dndflow-pdf, .dndflow-img {
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    color: white;
    opacity: 0;
    z-index: 1000;
    transition: 0.5s ease opacity, 0.5s ease visibility;
}

.dndflow-load h4, .dndflow-pdf h4, .dndflow-img h4 {
    margin-top: 200px;
}

.dndflow-load.active, .dndflow-pdf.active, .dndflow-img.active {
    visibility: visible;
    opacity: 1;
}

.dndflow-load.active img, .dndflow-pdf.active img, .dndflow-img.active img {
    animation: Img-logo-spin infinite 5s linear;
}

@keyframes Img-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.dndflow img.dndflow-logo {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.dndflow aside {
    padding-top: 50px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    background: #d4edf3;
    font-size: 12px;
    position: absolute;
    z-index: 99;
    top: 0;
    height: 100vh;
    left: 0;
    margin-left: 0;
    border-right: 1px solid rgba(0,0,0,0.1);
    width: 200px;
}

.dndflow aside > * {
    cursor: grab;
}

.dndflow aside > *:active {
    cursor: grabbing;
}

.dndflow aside .description {
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
    margin-left: 200px;
    width: calc(100vw - 200px)
}

.dndflow .react-flow__controls {
    right: 10px !important;
    bottom: 10px !important;
    left: auto;
}
