.inverter-node {
    background-image: url('../../images/inverter.png');
    padding: 10px;
    background-size: cover;
    height: 87px;
    width: 123px;
    position: relative;
}

.inverter-node .inverter-node-input-label {
    width: 300px;
    position: absolute;
    top: -23px;
    left: -67%;
    text-align: center;
}
