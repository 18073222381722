.batteryfuse-node {
    background-image: url('../../images/batteryfuse.png');
    padding: 10px;
    background-size: cover;
    height: 35px;
    width: 90px;
    position: relative;
}

.batteryfuse-node .batteryfuse-node-input-label {
    width: 200px;
    position: absolute;
    top: -21px;
    left: 50%;
    transform: translateX(-50%);
}
