.modal-home {
    background: rgba(255,255,255,0.8);
}

.modal-home .modal-content {
    border: none;
}

.modal-home-header {
    height: 47px;
}

.modal-home-header button.btn-close {

}

.modal-home-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.modal-home-body button {
    width: 100%;
    color: rgba(0,0,0,0.6);
}

.modal-home-body button:last-of-type {
    margin-top: 10px;
}

.modal-home-divider {
    width: 100%;
    height: 1px;
    margin: 10px 0;
    background: #b1b1b7;
}
