.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-flow__handle.source.connectable {

  width: 15px!important;
  height: 15px!important;

  background: whitesmoke!important;
  border: 1px solid black!important;
  border-radius: 4px!important;
}

.react-flow__node .node-remove {
  display: none;
}

.react-flow__node {
  border: 1px solid rgba(0,0,0,0);
}

.react-flow__node.selected {
  border: 1px solid red;
}

.react-flow__node.selected .node-remove {
  display: block;
  position: absolute;
  bottom: 50%;
  right: -15%;
  transform: translate(50%, 50%);
  background: rgba(2255,0,0,0.5);
  color: #fff;
  padding: 2px 10px;
  border-radius: 3px;
  box-shadow: 0 4px 4px -1px rgba(0,0,0,0.5);
  cursor: pointer;
}

.react-flow__node.selected .node-remove:hover {
  padding: 4px 12px;
  background: rgba(2255,0,0,1);
}

.react-flow__edges {
  z-index: 2 !important;
}

svg {
  pointer-events: none;
}

.react-flow__edge-path {
  stroke: #b1b1b7;
  /*stroke-width: 5px;*/
  cursor: pointer;
  pointer-events: auto;
}

.react-flow__nodes {
  z-index: 2;
}
