.switch4-node {
    background: url('../../images/switch4.png');
    padding: 10px;
    background-size: cover;
    height:47px;
    width: 47px;
    position: relative;
}

.switch4-node .switch4-node-input-label {
    width: 200px;
    position: absolute;
    top: 17px;
    right: -129px;

}
