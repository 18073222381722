
.dnd-if-mobile {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1001;

    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.dnd-if-mobile img {
    margin-top: calc(100vh / 2 - 150px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
}

.dnd-if-mobile h4 {
    margin-top: calc(100vh / 2 - 30px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
    color: #000000;
    font-weight: 200;
}

.dnd-if-mobile p {
    margin-top: calc(100vh / 2 + 60px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
    color: #000000;
    font-weight: 200;
    white-space: nowrap;
}

.dnd-if-mobile p a {
    color: #ffffff;
}
