.electricbreaker-node {
    background-image: url('../../images/electricbreaker.png');
    padding: 10px;
    background-size: cover;
    height: 92px;
    width: 12px;
    position: relative;
}

.electricbreaker-node .electricbreaker-node-input-label {
    width: 130px;
    position: absolute;
    top: 50%;
    left: 27px;
    text-align: left;
    transform: translateY(-20px);
}

.electricbreaker-node .node-remove {
    right: -36% !important;
}
