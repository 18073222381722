.inlinefuse-node {
    background-image: url('../../images/inlinefuse.png');
    padding: 10px;
    background-size: cover;
    height: 45px;
    width: 73px;
    position: relative;
}

.inlinefuse-node .inlinefuse-node-input-label {
    width: 200px;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
}
