.busbar-node {
    background-image: url('../../images/busbar.png');
    padding: 10px;
    background-size: cover;
    height: 26px;
    width: 117px;
    position: relative;
}

.busbar-node .busbar-node-input-label {
    width: 200px;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
}
