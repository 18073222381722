.react-flow__node-resize {
    z-index: 0 !important;
}

.resize-node {
    padding: 10px;
    background: #eaeaea;
    height: 90px;
    width: 190px;
    position: relative;
    border-radius: 5px;
}

.resize-node .node-remove {
    right: -20px !important;
}

.resize-node .resize-node-input-label {
    width: 200px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}
